<template>
  <ValidationObserver class="eui-u-h-full" slim ref="observer" v-slot="{ valid }">
    <EuiContainer style="height: calc(100vh - 180px);">
      <AddSignatoriesDialog
        :selected.sync="socialSignatory.authorizedUsers"
        @change="setSignatories"
        @closeDialog="addSignDialog = false"
        :open.sync="addSignDialog"/>
      <div v-if="alertError">
        <EuiAlert color="error" @click:close="alertError = false">
          {{ $t('signworkflows.create.step1.alert.title')}}
          <ul>
            <li v-for="error in errors" :key="error" class="text-white">
              {{ error }}
            </li>
          </ul>
        </EuiAlert>
      </div>
      <EuiGrid gutters>
        <EuiGridRow>
          <EuiGridColumn>
            <div class="flex items-center">
            <EuiButton @click="goBack" iconOnly size="large" variant="text">
              <EuiIcon color="primary"  name="chevron_left"/>
            </EuiButton>
            <EuiHeading :level="1" bold>{{ $t('signatories.social.create.title') }}</EuiHeading>
            </div>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiBlock :title="$i18n.t('signatories.social.create.information.title')">
              <EuiGrid>
                <EuiGridRow>
                  <EuiGridColumn>
                    <ValidationProvider :name="$t('signatories.social.create.information.companyName.label')" rules="required" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    :label="$t('signatories.social.create.information.companyName.label')"
                                    type="text"
                                    v-model="socialSignatory.companyName"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"/>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('signatories.social.create.information.email.label')" rules="required|email" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    :label="$t('signatories.social.create.information.email.label')"
                                    type="email"
                                    v-model="socialSignatory.email"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"/>
                    </ValidationProvider>
                  </EuiGridColumn>
                  <EuiGridColumn>
                    <EuiTextField :invalid="validationErrors.logo" class="eui-u-ml-4">
                      <EuiLabel>{{ $t('signatories.social.create.information.logo.label') }}</EuiLabel>
                      <input ref="logoUploader"
                             style="display: none"
                             type="file"
                             multiple="false"
                             accept="image/png, image/jpeg"
                             @click="resetLogoUploader"
                             @change="handleUploadLogo($event.target.files[0])">
                      <div class="mt-1">
                        <EuiButton @click="$refs.logoUploader.click()" variant="raised" size="small" color="primary">
                          {{ $t('button.upload') }}
                        </EuiButton>
                      </div>
                      <div class="es-signatory__logo" v-if="socialSignatory.logo">
                        <img :src="'data:image/png;base64,' + socialSignatory.logo.fileContent" />
                        <div class="es-signatory__logo__hover-panel">
                          <EuiIcon name="more_vert"/>
                          <EuiButton size="large" variant="text" @click="socialSignatory.logo = undefined"  iconOnly color="primary">
                            <EuiIcon outlined name="delete"/>
                          </EuiButton>
                        </div>
                      </div>
                      <template v-slot:errorMessage>{{ $t('signatories.social.create.information.logo.error')}}</template>
                    </EuiTextField>
                  </EuiGridColumn>
                </EuiGridRow>
              </EuiGrid>
            </EuiBlock>
          </EuiGridColumn>
          </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiBlock :title="$i18n.t('signatories.social.create.users.title')">
              <p v-if="socialSignatory.authorizedUsers.length === 0" class="mb-4" :class="{'text-alert': validationErrors.authorizedUsers}">
                {{ $t('signatories.social.create.users.noUsers') }}
              </p>
                <EuiTable v-if="socialSignatory.authorizedUsers.length > 0" class="mb-4">
                  <EuiTableHeader>
                    <EuiTableRow>
                      <!-- full name -->
                      <EuiTableHead>{{ $t('signatories.users.index.table.name') }}</EuiTableHead>
                      <EuiTableHead>{{ $t('signatories.users.index.table.group') }}</EuiTableHead>
                      <EuiTableHead dataType="button"/>
                    </EuiTableRow>
                  </EuiTableHeader>
                  <EuiTableBody>
                    <!-- table row -->
                    <EuiTableRow v-for="item in socialSignatory.authorizedUsers" :key="item.userId">
                      <!-- full name -->
                      <EuiTableCell>{{ item.fullname }}</EuiTableCell>
                      <EuiTableCell>{{ item.group }}</EuiTableCell>
                      <EuiTableCell dataType="button">
                        <EuiButton iconOnly color="primary" @click="removeAuthorizedUser(item)">
                          <EuiIcon name="delete"/>
                        </EuiButton>
                      </EuiTableCell>
                    </EuiTableRow>
                  </EuiTableBody>
                </EuiTable>
              <EuiButton variant="raised" color="primary" size="small" @click="addSignDialog = true">
                {{ $t('button.add') }}
              </EuiButton>
            </EuiBlock>
          </EuiGridColumn>
          <EuiGridColumn>
            <EuiBlock :title="$i18n.t('signatories.social.create.certificate.title')">
              <div class="flex">
              <div class="flex flex-col flex-1">
                <ValidationProvider :name="$t('signatories.social.create.certificate.password.label')" rules="required" v-slot="{ errors, valid, invalid }">
                  <EuiTextField adaptSize
                                class="mb-2"
                                :label="$t('signatories.social.create.certificate.password.label')"
                                :type="passwordFieldType"
                                v-model="socialSignatory.password"
                                :valid="valid && validatePassword"
                                :invalid="errors.length > 0 || !validatePassword"
                                :warning="invalid && errors.length === 0"
                                :errorMessage="errors[0]"/>
                </ValidationProvider>
                <ValidationProvider :name="$t('signatories.social.create.certificate.passwordConfirmation.label')" rules="required" v-slot="{ errors, valid, invalid }">
                  <EuiTextField adaptSize
                                class="mb-2"
                                :label="$t('signatories.social.create.certificate.passwordConfirmation.label')"
                                :type="passwordFieldType"
                                v-model="socialSignatory.confirmPassword"
                                :valid="valid && validatePassword"
                                :invalid="errors.length > 0 || !validatePassword"
                                :warning="invalid && errors.length === 0"
                                :errorMessage="errors[0]"/>
                </ValidationProvider>
                <div>
                  <EuiButton variant="text" color="primary" size="small" @click.prevent="switchPasswordVisibility">
                    <template v-if="passwordFieldType === 'password'">
                      {{ $t('settings.users.create.password.show') }}
                    </template>
                    <template v-else>
                      {{ $t('settings.users.create.password.hide') }}
                    </template>
                  </EuiButton>
                </div>
              </div>
                <div class="flex flex-col  flex-1">
                  <EuiTextField :invalid="validationErrors.uploadCertificate" class="pl-3">
                    <EuiLabel>{{ $t('signatories.social.create.certificate.upload.label') }}</EuiLabel>
                    <input ref="certificateUploader"
                           style="display: none"
                           type="file"
                           accept="application/x-pkcs12"
                           multiple=false
                           @click="resetCertificateUploader"
                           @change="handleUploadCertificate($event.target.files[0])">
                    <template v-slot:errorMessage>{{ $t('signatories.social.create.certificate.upload.error') }}</template>
                    <div class="mt-1">
                      <EuiButton @click="$refs.certificateUploader.click()" variant="raised" size="small" color="primary">
                        {{ $t('button.upload') }}
                      </EuiButton>
                    </div>
                  </EuiTextField>
                  <div class="pl-3 flex" v-if="socialSignatory.uploadCertificate">
                    <p>{{ socialSignatory.uploadCertificate.name }}</p><EuiButton iconOnly size="small" @click="socialSignatory.uploadCertificate = undefined"><EuiIcon name="close" /></EuiButton>
                  </div>
                </div>
              </div>
            </EuiBlock>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </EuiContainer>
    <EuiBottomBar :class="bottomBarStyle">
      <EuiButton variant="text" color="primary" @click="goBack">{{ $t('button.cancel') }}</EuiButton>
      <EuiButton variant="raised" color="primary" @click="create" :disabled="(!valid && !socialSignatory.logo && !socialSignatory.uploadCertificate) || !validatePassword">{{ $t('button.create') }}</EuiButton>
    </EuiBottomBar>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import AddSignatoriesDialog from './AddSignatoriesDialog';
import DOMpurify from 'dompurify';

export default {
  name: 'CreateSocialSignatory',
  components: {
    AddSignatoriesDialog,
  },
  watch: {
    socialSignatory: {
      handler() {
        if (this.errors.length > 0) {
          this.checkForm();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      isSidenavOpen: (state) => state.ModuleEdocSign.ui.isSidenavOpen,
      isMiniVariant: (state) => state.ModuleEdocSign.ui.isMiniVariant,
      isMobileView: (state) => state.application.responsive.xs,
    }),
    bottomBarStyle() {
      return {
        '-mini': !this.isMobileView && this.isSidenavOpen && this.isMiniVariant,
        '-default': this.isSidenavOpen && !this.isMiniVariant,
      };
    },
    validatePassword() {
      return this.socialSignatory.confirmPassword === this.socialSignatory.password;
    },
  },
  data() {
    return {
      errors: [],
      validationErrors: {},
      addSignDialog: false,
      alertError: false,
      socialSignatory: {
        companyName: '',
        email: '',
        logo: undefined,
        authorizedUsers: [],
        uploadCertificate: undefined,
        password: '',
        confirmPassword: '',
      },
      passwordFieldType: 'password',
    };
  },
  methods: {
    ...mapActions({
      createSignatory: 'ModuleEdocSign/signatory/createSignatory',
    }),
    removeAuthorizedUser(authorizedUser) {
      this.socialSignatory.authorizedUsers =
        this.socialSignatory.authorizedUsers.filter((element) => element !== authorizedUser);
    },
    switchPasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    setSignatories(signatories) {
      signatories.concat(this.socialSignatory.authorizedUsers);
      const noDuplicateMap = new Map(signatories.map(user => [user.userId, user]));
      const noDuplicateArray = [...noDuplicateMap.values()];
      this.socialSignatory.authorizedUsers = noDuplicateArray.sort((a, b) => ((`${a.fullname}` > `${b.fullname}`) ? 1 : -1));
    },
    initValidations() {
      this.validationErrors = {
        companyName: false,
        email: false,
        logo: false,
        authorizedUsers: false,
        uploadCertificate: false,
        password: false,
      };
    },
    goBack() {
      this.$router.push({ name: 'signatories.social.index' });
    },
    checkForm() {
      let isValid = true;
      this.errors = [];
      this.initValidations();
      if (!this.socialSignatory.companyName) {
        isValid = false;
        this.validationErrors.companyName = true;
        this.errors.push(this.$i18n.t('signatories.social.create.information.companyName.error'));
      }

      if (!this.validEmail(this.socialSignatory.email)) {
        isValid = false;
        this.validationErrors.email = true;
        this.errors.push(this.$i18n.t('signatories.social.create.information.email.error'));
      }
      if (!this.socialSignatory.logo) {
        isValid = false;
        this.validationErrors.logo = true;
        this.errors.push(this.$i18n.t('signatories.social.create.information.logo.error'));
      }

      if (this.socialSignatory.authorizedUsers.length === 0) {
        isValid = false;
        this.validationErrors.authorizedUsers = true;
        this.errors.push(this.$i18n.t('signatories.social.create.authorizedUsers.error'));
      }

      if (!this.socialSignatory.uploadCertificate) {
        isValid = false;
        this.validationErrors.uploadCertificate = true;
        this.errors.push(this.$i18n.t('signatories.social.create.certificate.upload.error'));
      }

      if (this.socialSignatory.password.length === 0) {
        isValid = false;
        this.validationErrors.password = true;
        this.errors.push(this.$i18n.t('signatories.social.create.certificate.password.error.required'));
      } else if (this.socialSignatory.password !== this.socialSignatory.confirmPassword) {
        isValid = false;
        this.validationErrors.password = true;
        this.errors.push(this.$i18n.t('signatories.social.create.certificate.password.error.mismatch'));
      }

      this.alertError = !isValid;
      return isValid;
    },
    validEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    transformToSend(socialSignatory) {
      return {
        lastname: DOMpurify.sanitize(socialSignatory.companyName),
        email: socialSignatory.email,
        entityType: 'socialSigner',
        logo: socialSignatory.logo.fileContent,
        certificateContent: socialSignatory.uploadCertificate.fileContent,
        clearSignatureCode: socialSignatory.password,
        authorizedUsers: socialSignatory.authorizedUsers.map((value) => value.userId),
      };
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.result);
      });
    },
    resetLogoUploader() {
      this.$refs.logoUploader.value = '';
    },
    handleUploadLogo(file) {
      this.getBase64(file)
        .then((res) => {
          const id = this.$uuid.v4();
          const fileContent = res.split(';')[1].split(',')[1];
          this.socialSignatory.logo = {
            id,
            name: file.name,
            fileContent,
            extension: file.name.split('.').pop(),
          };
        });
    },
    resetCertificateUploader() {
      this.$refs.certificateUploader.value = '';
    },
    handleUploadCertificate(file) {
      this.socialSignatory.uploadCertificate = file;
      this.getBase64(file)
        .then((res) => {
          const fileContent = res.split(';')[1].split(',')[1];
          this.socialSignatory.uploadCertificate = {
            name: file.name,
            fileContent,
          };
        });
    },
    create() {
      if (this.checkForm()) {
        this.createSignatory(this.transformToSend(this.socialSignatory))
          .then(() => this.goBack());
      } else {
        document.getElementsByClassName('eui-o-layout__main')[0].scrollTop = 0;
      }
    },
  },
};
</script>
